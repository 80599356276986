import React from 'react';

import Layout from 'components/Layout';
import LandScanSplash from '../assets/images/landscan_imagery_BG.png';
import LandScanSpecs from '../assets/images/LandScan-specs.png';

const About = () => (
  <Layout>
    <div className="about-page subpage">
      <div
        className="about-page-header subpage__header"
        style={{
          backgroundImage: `url(${LandScanSplash})`
        }}
      >
        <h1>About LandScan</h1>
      </div>
      <div className="about-page-content subpage__content">
        <section>
          The LandScan Program was initiated at Oak Ridge National Laboratory
          (ORNL) in 1997 to address the need for improved estimates of
          population for consequence assessment. For example, natural and
          manmade disasters across the globe place vast populations at risk,
          often with little or no advance warning. This motivated the need for a
          more realistic population distribution that could capture where people
          are likely to be throughout the course of a day, not just residential
          locations as are reported in official censuses. Further, it was
          critical to develop highly resolved estimates so that they were useful
          to evaluate to events across multiple geographic scales. This initial
          effort is what is known as LandScan Global and has been updated
          annually since the first version in 1998.
        </section>
        <section>
          Building on the modeling approach developed for LandScan Global, and
          taking advantage of higher quality data available for the U.S., we
          improved on both the spatial and the temporal resolution with our
          first version of LandScan USA in 2004. LandScan USA was created with
          the goal of capturing the diurnal variation of population that is
          critical for a variety of analyses and actions including emergency
          preparedness and response. In 2016, the original LandScan USA model
          was re-engineered to incoroporate advances in geospatial technology,
          machine learning approaches, and new input data sources. Since then,
          we have made annual improvements to the underlying model and released
          a new version of the dataset each year.
        </section>
        <section>
          Around the time LandScan USA was first initiated, ORNL was also
          pioneering work in machine learning and computer vision, specifically
          to identify anthropogenic signals apparent in overhead imagery. This
          work ultimately enabled rapid, large-scale detection of human
          settlements from high resolution imagery and became the basis for
          early efforts to develop an improved resolution population
          distribution outside the U.S. known as Landscan HD. LandScan HD was
          built on the premise that most parts of the would would be data poor
          in terms of the availability of high quality, current census
          information as well as spatial delineations of human habitation. As
          such, the LandScan HD model employs a mixture of multi-modal data
          fusion, spatial data science, big data resources, and satellite
          imagery exploitation. The first country-scale LandScan HD dataset was
          created in 2014 and a continuous stream of new country-scale datasets
          have been developed ever since.
        </section>
        <img
          src={LandScanSpecs}
          className="responsive-image"
          alt="LandScan Statistics"
        />
        <h2>LandScan Global</h2>
        <section>
          Using an innovative approach that combines geospatial science, remote
          sensing technology, and machine learning algorithms, LandScan Global
          is the finest resolution global population distribution data available
          representing an ambient (24 hour average) population. The LandScan
          Global algorithm, an R&D 100 Award Winner, uses spatial data,
          high-resolution imagery exploitation, and a multi-variable dasymetric
          modeling approach to disaggregate census counts within an
          administrative boundary. Since no single population distribution model
          can account for the differences in spatial data availability, quality,
          scale, and accuracy as well as the differences in cultural settlement
          practices, LandScan population distribution models are tailored to
          match the data conditions and geographical nature of each individual
          country and region. By modeling an ambient population, LandScan Global
          captures the full potential activity space of people throughout the
          course of the day and night rather than just a residential location.
        </section>
        <h2>LandScan USA</h2>
        <section>
          LandScan USA employs a dasymetric population distribution model to
          depict the location of people in both space and time. The model is an
          extension and enhancement of the methodology used in the LandScan
          Global project, but also includes diurnal variations at a much finer
          spatial resolution. Nighttime (residential) and daytime population
          data are produced at 3 arc-second (~90m) resolution. The population
          distribution model incorporates census data at the block-level;
          demographic attributes including age, sex, and race; and integrates
          other socioeconomic data such as journey to work. Ancillary spatial
          data including high resolution imagery, transportation infrastructure,
          lidar and automated building extractions, and parcels are used to
          spatially refine the population distribution. Other vital information
          used to enhance the LandScan USA output includes cultural attractions,
          academic institutions, prisons, shopping malls, and commercial areas.
          Validation and verification is accomplished on a county-by-county
          basis by assessing the input data for inaccurate, missing, or outdated
          data.
        </section>
        <h2>LandScan HD</h2>
        <section>
          Leveraging over a quarter century of expertise in developing high
          resolution, large scale population distribution models, and in
          response to growing demand for fine scale population data for areas
          outside the United States, ORNL is creating ~90 meter resolution
          population distribution datasets for areas around the world. LandScan
          HD modeling is tailored to the unique geography and data conditions of
          individual cities, countries, or regions. LandScan HD incorporates
          current land use and infrastructure data from a variety of sources,
          applies occupancy estimates from ORNL’s Population Density Tables
          (PDT) project, and leverages novel image processing algorithms
          developed at ORNL to rapidly map building structures and neighborhood
          areas using high-performance computing environments. In this way,
          LandScan HD is developed using a “bottom-up” approach where high
          resolution population estimates are not dependent on a recently
          conducted, high quality census. This approach is particularly useful
          for parts of the world that regularly experience large changes in
          population distribution due to rapid growth, natural hazards, or
          conflict.
        </section>
        <h2>FAQs</h2>
        <section>
          <b>What does the value in the LandScan datasets represent?</b> The
          value of each cell represents an estimated population count for that
          cell.
          <br />
          <br />
          <b>What is the projection/spheroid of the LandScan data?</b> The
          LandScan datasets are not projected, but can be used as a “geographic”
          projection with various GIS software packages (e.g., ERDAS and ESRI).
          The data are referenced by latitude/longitude (WGS84) coordinates.
          <br />
          <br />
          <b>
            Can I convert the LandScan data from raster to vector format?
          </b>{' '}
          We do not recommend converting LandScan data to vector polygons. This
          is because when converting from raster to vector, any adjacent cells
          that share the same value will be merged to a single larger polygon
          with the original value maintained. So, for example:
          <ul>
            <li>Two adjacent cells, both with a value of 100</li>
            <li>
              The resulting single polygon would represent the original two
              cells
            </li>
            <li>
              The resulting single polygon would have a value of 100 rather than
              the sum of the value of the two cells which would be 200
            </li>
            <li>
              This result essentially decreases the original population estimate
              by 100
            </li>
          </ul>
          If users must convert the data from raster format to vector format, we
          recommend converting to a point representation. This way each point
          value will maintain the original cell population as was represented in
          the raster format.
          <br />
          <br />
          <b>How do I calculate population density?</b> Gridded data requires
          addressing the size of each grid(s) in a designated density area. For
          example, if your area of interest includes 10 LandScan Global cells,
          you will need to:
          <ul>
            <li>convert the LandScan raster cells to polygons</li>
            <li>project the polygons to your desired projection</li>
            <li>sum the area of those 10 polygons</li>
            <li>
              divide total population for the original 10 cells by the sum of
              the area (total pop / area sum = density sq)
            </li>
          </ul>
          Some things to keep in mind: Because the data are originally in a
          geographic coordinate system, the cells become smaller (more narrow)
          the closer they get to the Poles. This holds true even after the
          polygons are projected. Therefore, remember to treat every area of
          interest with unique area calculations (i.e. you cannot assume another
          area of interest with 10 cells will have the same area sum).
          Additionally, if some polygons appear to have merged during the
          conversion from raster format, this is because two adjacent raster
          cells shared an identical population count. This is acceptable since
          the only concern is to calculate the total area sum.
          <br />
          <br />
          <b>
            What is the best practice for projecting LandScan data into a
            projected coordinate system to maintain the integrity of the
            population estimates?
          </b>{' '}
          Projecting LandScan data into another coordinate system will result in
          resampling of the data and population loss will result. However, a
          suggested work-around is described below:
          <ol>
            <li>
              Convert the LandScan raster data for your area of interest to
              (vector) points.
            </li>
            <li>Project the points to your desired coordinate system.</li>
            <li>
              Sum the points by your desired output grid cell size (e.g. 1km)
              -this can be a little tricky and can be accomplished a couple of
              ways:
            </li>
            a. &nbsp; Create an indexed matrix of polygons that mimic the grid
            extent and resolution you desire and sum the points values inside
            each polygon; then convert the summed value to a (raster) grid.
            <br />
            <br />
            b. &nbsp; Convert the projected points to a (raster) grid using your
            final output extent, but at a finer resolution (e.g. 0.25km or
            0.5km). This will ensure that all population counts will be used.
            Aggregate this finer resolution (raster) grid to your desired output
            cell size (e.g. aggregate factor of 2 or 4 depending on the cell
            size used). It is important to keep extents and cell size on
            divisible multiples to prevent data loss or spatial &quot;grid
            creep&quot;.
          </ol>
          It’s advisable to first test this approach on a small area to validate
          the procedure is being correctly implemented and returning expected
          results.
        </section>
      </div>
    </div>
  </Layout>
);

export default About;
